// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alterar-senha-tsx": () => import("./../../../src/pages/alterar-senha.tsx" /* webpackChunkName: "component---src-pages-alterar-senha-tsx" */),
  "component---src-pages-anuncio-peca-tsx": () => import("./../../../src/pages/anuncio-peca.tsx" /* webpackChunkName: "component---src-pages-anuncio-peca-tsx" */),
  "component---src-pages-anuncio-tsx": () => import("./../../../src/pages/anuncio.tsx" /* webpackChunkName: "component---src-pages-anuncio-tsx" */),
  "component---src-pages-anuncios-autoscarplay-tsx": () => import("./../../../src/pages/anuncios-autoscarplay.tsx" /* webpackChunkName: "component---src-pages-anuncios-autoscarplay-tsx" */),
  "component---src-pages-busca-loja-tsx": () => import("./../../../src/pages/busca-loja.tsx" /* webpackChunkName: "component---src-pages-busca-loja-tsx" */),
  "component---src-pages-busca-tsx": () => import("./../../../src/pages/busca.tsx" /* webpackChunkName: "component---src-pages-busca-tsx" */),
  "component---src-pages-cadastrar-anuncio-tsx": () => import("./../../../src/pages/cadastrar-anuncio.tsx" /* webpackChunkName: "component---src-pages-cadastrar-anuncio-tsx" */),
  "component---src-pages-cadastro-modelo-pecas-tsx": () => import("./../../../src/pages/cadastro-modelo-pecas.tsx" /* webpackChunkName: "component---src-pages-cadastro-modelo-pecas-tsx" */),
  "component---src-pages-cadastro-modelo-tsx": () => import("./../../../src/pages/cadastro-modelo.tsx" /* webpackChunkName: "component---src-pages-cadastro-modelo-tsx" */),
  "component---src-pages-cadastro-pj-tsx": () => import("./../../../src/pages/cadastro-pj.tsx" /* webpackChunkName: "component---src-pages-cadastro-pj-tsx" */),
  "component---src-pages-editar-anuncio-tsx": () => import("./../../../src/pages/editar-anuncio.tsx" /* webpackChunkName: "component---src-pages-editar-anuncio-tsx" */),
  "component---src-pages-editar-perfil-tsx": () => import("./../../../src/pages/editar-perfil.tsx" /* webpackChunkName: "component---src-pages-editar-perfil-tsx" */),
  "component---src-pages-esqueci-senha-tsx": () => import("./../../../src/pages/esqueci-senha.tsx" /* webpackChunkName: "component---src-pages-esqueci-senha-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gerenciamento-usuarios-tsx": () => import("./../../../src/pages/gerenciamento-usuarios.tsx" /* webpackChunkName: "component---src-pages-gerenciamento-usuarios-tsx" */),
  "component---src-pages-grid-api-js": () => import("./../../../src/pages/grid-api.js" /* webpackChunkName: "component---src-pages-grid-api-js" */),
  "component---src-pages-how-to-sell-tsx": () => import("./../../../src/pages/how-to-sell.tsx" /* webpackChunkName: "component---src-pages-how-to-sell-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institutional-pages-tsx": () => import("./../../../src/pages/institutional-pages.tsx" /* webpackChunkName: "component---src-pages-institutional-pages-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-loja-tsx": () => import("./../../../src/pages/loja.tsx" /* webpackChunkName: "component---src-pages-loja-tsx" */),
  "component---src-pages-meus-anuncios-tsx": () => import("./../../../src/pages/meus-anuncios.tsx" /* webpackChunkName: "component---src-pages-meus-anuncios-tsx" */),
  "component---src-pages-meus-pedidos-tsx": () => import("./../../../src/pages/meus-pedidos.tsx" /* webpackChunkName: "component---src-pages-meus-pedidos-tsx" */),
  "component---src-pages-minhas-vendas-tsx": () => import("./../../../src/pages/minhas-vendas.tsx" /* webpackChunkName: "component---src-pages-minhas-vendas-tsx" */),
  "component---src-pages-planos-manuntencao-tsx": () => import("./../../../src/pages/planos-manuntencao.tsx" /* webpackChunkName: "component---src-pages-planos-manuntencao-tsx" */),
  "component---src-pages-planos-tsx": () => import("./../../../src/pages/planos.tsx" /* webpackChunkName: "component---src-pages-planos-tsx" */),
  "component---src-pages-private-route-tsx": () => import("./../../../src/pages/private-route.tsx" /* webpackChunkName: "component---src-pages-private-route-tsx" */),
  "component---src-pages-processar-pagamento-tsx": () => import("./../../../src/pages/processar-pagamento.tsx" /* webpackChunkName: "component---src-pages-processar-pagamento-tsx" */),
  "component---src-pages-selecionar-tipo-de-anuncio-tsx": () => import("./../../../src/pages/selecionar-tipo-de-anuncio.tsx" /* webpackChunkName: "component---src-pages-selecionar-tipo-de-anuncio-tsx" */),
  "component---src-pages-sprint-seven-aviso-pj-tsx": () => import("./../../../src/pages/sprint-seven/aviso-pj.tsx" /* webpackChunkName: "component---src-pages-sprint-seven-aviso-pj-tsx" */),
  "component---src-pages-sprint-seven-cobranca-tsx": () => import("./../../../src/pages/sprint-seven/cobranca.tsx" /* webpackChunkName: "component---src-pages-sprint-seven-cobranca-tsx" */),
  "component---src-pages-sprint-seven-loader-tsx": () => import("./../../../src/pages/sprint-seven/loader.tsx" /* webpackChunkName: "component---src-pages-sprint-seven-loader-tsx" */)
}

